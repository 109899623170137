import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import type { HeroButton_contentBlock$key } from './__generated__/HeroButton_contentBlock.graphql';
import { HeroCtaButton } from './HeroCtaButtonStyle';

const contentBlockFragment = graphql`
  fragment HeroButton_contentBlock on Button {
    ...FlowRouterButton_button
  }
`;

type HeroButtonComponentProps = {
  contentBlock: HeroButton_contentBlock$key;
  onHeroClick: () => void;
};

const HeroButton: FC<HeroButtonComponentProps> = ({ onHeroClick, ...props }) => {
  const contentBlock = useFragment(contentBlockFragment, props.contentBlock);

  return <HeroCtaButton variant="primary" button={contentBlock} size="hero" onClick={onHeroClick} />;
};

export default HeroButton;
