/**
 * @generated SignedSource<<8be8a5090a13de938e7764c993077c58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroButton_contentBlock$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FlowRouterButton_button">;
  readonly " $fragmentType": "HeroButton_contentBlock";
};
export type HeroButton_contentBlock$key = {
  readonly " $data"?: HeroButton_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroButton_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroButton_contentBlock",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FlowRouterButton_button"
    }
  ],
  "type": "Button",
  "abstractKey": null
};

(node as any).hash = "0d5261aadfa50c666e78fde13c0d7e80";

export default node;
